<template>
  <v-card>
    <v-tabs background-color="grey" dark>
      <v-tab>Consultant Progress Report</v-tab>
      <v-tab-item>
        <v-col
          ><div class="ma-4"><consultant-report /></div
        ></v-col>
      </v-tab-item>
      <v-tab>Leads Stats</v-tab>
      <v-tab-item>
        <v-row
          ><v-col cols="12" class="ma-4"> <leads-graph /> </v-col
        ></v-row>
      </v-tab-item>
      <v-tab>Lead Progress</v-tab>
      <v-tab-item>
        <report-with-to-do />
      </v-tab-item>
      <v-tab>Clients products</v-tab>
      <v-tab-item>
        <clients-products />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ClientsProducts from "../../components/Base/DashboardReports/ClientsProducts.vue";
// import ReferralCount from "../../components/Base/ReferralCount.vue";
import ConsultantReport from "../../components/Base/DashboardReports/ConsultantReport.vue";
import LeadsGraph from "../../components/Base/DashboardReports/LeadsGraph.vue";
import ReportWithToDo from "../../components/Base/DashboardReports/ReportWithToDo.vue";
export default {
  name: "Dashboard",
  components: {
    // ReferralCount,
    ConsultantReport,
    LeadsGraph,
    ReportWithToDo,
    ClientsProducts,
  },
  data() {
    return {
      statusCounts: {},
      statusOptions: [
        "Call Back - Quoted",
        "Call Back By Request",
        "Call Back - Unavailable",
        "Hung Up",
        "Voicemail",
        "Shopping Around",
        "Not Interested",
        "Converse Via Email",
        "Closed - Won",
        "Closed Lost",
        "Dead",
        "Restricted by Scheme Rules",
        "Not Interested due to affordability",
        "Working directly with Scheme",
        "Working with Another Broker",
        "Client does not answer Phone",
        "Unable to contact client",
        "Incorrect contact details",
        "Not Interested due to affordability",
        "Not Interested due to service received",
        "Not Interested in Product",
        "Working directly with Scheme",
        "Working with Another Broker",
        "Underwriting -Condition Specific",
        "Underwriting -LJP",
        "Client does not answer Phone",
        "Client does not reply to Email",
        "Unable to contact client",
        "Incorrect contact details",
        "Not Interested due to affordability",
        "Not Interested due to service received",
        "Not Interested in Product",
        "Working directly with Scheme",
        "Working with Another Broker",
        "Underwriting -Condition Specific",
        "Underwriting -LJP",
        "Client does not answer Phone",
        "Client does not reply to Email",
        "Unable to contact client",
      ],
      limit: 10, // Set your desired limit
      page: 1, // Set your desired page number
    };
  },
};
</script>
